<template lang="">
    <div class="centerImageContainer">
            <div class="title betweenFlex">
                <div v-for="item, index in titleList" class="titleItem" :class="{ active: index == current }"
                    @click="changeIndex(index)">
                    <div>{{ item }}</div>
                    <div class="activeLine"></div>
                </div>
            </div>
            <div class="deepImageContainer betweenFlex">
                <div class="deepImageItem " v-for="item, imgIndex in 6" @click="goToXinWenDetail(item)">
                    <img src="@/assets/mobile/xinWen/Rectangle 440@2x.png" class="topImg">
                    <div class="bottomText">
                        <p class="bigTitle">2023-11-16</p>
                        <div class="content">科学家发现有望治疗 胰腺癌的新方法</div>
                        <img src="@/assets/mobile/xinWen/Arrow 2@2x.png" class="bottomImg">
                    </div>
                </div>
            </div>
            <div class="fenYeContainer">
                <div class="fenYeItem" v-for="item,fenYeIndex in 7" :class="{ fenYeActive: fenYeIndex == currentFenYe }" @click="changeFenYeIndex(fenYeIndex)">{{ item }}
                </div>
                <div class="fenYeItem">></div>
            </div>
        </div>
</template>
<script>
export default {
    data() {
        return {
            current: 0,//切换标识
            titleList: [//导航栏数据
                "企业动态",
                "行业资讯"
            ],
            currentFenYe: 0
        };
    },
    mounted(){
        
    },
    methods: {
        changeIndex(index) {
            this.current = index
        },
        changeFenYeIndex(index){
            this.currentFenYe = index
        },
        goToXinWenDetail(item){
            this.$router.push('/mbXinWenCenter/mbCenterDetail?id='+item)
        }
    }
}
</script>
<style lang="less" scoped>
    
.fenYeActive {
    width: 24px;
    height: 24px;
    background: #BD0048 !important;
    border-radius: 2px 2px 2px 2px;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #FFFFFF !important;
    line-height: 24px;
    
}

.fenYeContainer {
    width: 335px;
    height: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .fenYeItem {
        width: 24px;
        height: 24px;
        background: #FFFFFF;
        border-radius: 2px 2px 2px 2px;
        text-align: center;
        border: 1px solid rgba(189, 0, 72, 0.25);
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        margin-right: 10px;
    }
    .fenYeItem:last-child{
        font-size: 16px;
    }
}

.centerImageContainer {
    box-sizing: border-box;
    padding: 30px 20px 60px 20px;
    background: #F7F7F7;

    .title {
        margin: 0px 83px;

        .titleItem {
            height: 22px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 19px;
            margin-right: 40px;
            position: relative;


        }

        .titleItem:last-child {
            margin-right: 0;
        }

    }

    .deepImageContainer {
        margin-top: 53px;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .deepImageItem {
            width: 162px;
            height: 240px;
            background: #FFFFFF;
            margin-bottom: 10px;
            border-radius: 8px 8px 8px 8px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .topImg {
                width: 162px;
                height: 120px;
                border-radius: 8px 8px 0px 0px;
            }

            .bottomText {
                box-sizing: border-box;
                padding-top: 20px;
                padding-left: 10px;
                position: relative;

                .bigTitle {
                    height: 22px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 22px;

                }

                .content {
                    margin-top: 3px;
                    height: 44px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }

                .bottomImg {
                    position: absolute;
                    right: 10px;
                    width: 30px;
                    height: 15px;
                    bottom: -20px;
                }
            }
        }
    }
}

.active {
    /*激活样式*/
    color: #BD0048 !important;
    position: relative;
}

.active .activeLine {
    /*激活样式*/
    content: '';
    position: absolute;
    width: 40px;
    height: 4px;
    background: #BD0048;
    border-radius: 8px 8px 8px 8px;
    left: 50%;
    top: 32px;
    transform: translate(-50%, -50%);

}

</style>