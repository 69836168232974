<template>
    <div class="headerContainer betweenFlex">
        <div class="left startFlex">
            <img src="@/assets/pc/wesite-logo 1@2x.png" class="logoImg">
            <div class="title">广州暖善社区居家养老服务有限公司</div>
        </div>
        <div class="right startFlex">
            <div class="text" v-for="(item, index) in nav" :class="{ active: index == current ,active: index == currentData}"   @click="go(index)"> {{ item}}
                <div class="activeLine"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pcHeader',

    data() {
        return {
          
            nav: [//导航栏数据
                '首页',
                '新闻中心',
                // '服务站点',
                // '联系方式'
            ],
            currentData:0
        };
    },
    props:['current'],
    mounted() {
        this.currentData = this.current
    },

    methods: {
        // 导航栏切换
        go(index) {
            this.currentData = index//激活样式
            switch (index) {
                case 0:
                    this.$router.push('/pcHome')
                    
                    break;
                case 1:
                    this.$router.push('/pcXinWenCenter')
                    break;
                case 2:
                    // this.$router.push('/mbFuWuCenter')
                    
                    break;
                case 3:
                    // this.$router.push('/mbContact')
                    
                    break;

            }
        }
    },
};
</script>

<style lang="less" scoped>
.active {
    /*激活样式*/
    color: #BD0048  !important;
    position: relative;
}

.active .activeLine {
    /*激活样式*/
    content: '';
    position: absolute;
    width: 24Px;
    height: 4Px;
    left: 50%;
    
    top:42Px;
    transform: translate(-50%, -50%) ;
    background: #BD0048;
    border-radius: 8Px 8Px 8Px 8Px;
}

.headerContainer {
    width: 1728Px;
    height: 100Px;
    background: rgba(51, 51, 51, 0.4);
    padding-left: 228Px;
    padding-right: 444Px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    margin: 0 auto;
    .left {
        .logoImg {
            width: 48Px;
            width:41Px;
            margin-right: 30Px;
        }

        .title {
            
            // width: 192Px;
            // height: 34Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28Px;
        }
    }

    .right {

        .text {
            margin: 33Px 0Px 33Px 60Px;
            height: 34Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28Px;
            cursor: pointer;
            
        }
    }
}
</style>