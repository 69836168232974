import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import mbHeader from './components/mbHeader.vue'
import pagination from './components/pagination.vue'
Vue.component('pagination',pagination)
Vue.component('mbHeader',mbHeader)
import pcHeader from './components/pcHeader.vue'
Vue.component('pcHeader',pcHeader)
import pcFooter from './components/pcFooter.vue'
Vue.component('pcFooter',pcFooter)
import mbFooter from './components/mbFooter.vue'
Vue.component('mbFooter',mbFooter)
import 'amfe-flexible'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router

}).$mount('#app')
