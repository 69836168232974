<template >
    <div>
        <mbHeader></mbHeader>
        
        <mbFooter></mbFooter>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less">
    
</style>