<template>
    <div class="xinWenListContainer">
        <div class="top betweenFlex">
            <div v-for="item, index in titleList" class="titleItem" :class="{ active: index == current }"
                @click="changeIndex(index)">
                <div>{{ item }}</div>
                <div class="activeLine"></div>
            </div>
        </div>
        <div class="List ">
            <div class="item betweenFlex" v-for="item, index in 5" @click="goToXinWenDetail(item)">
                <div class="left">
                    <div class="day">11-16</div>
                    <div class="year">2023</div>
                </div>
                <div class="right">
                    <div class="rightTitle">远离心梗 专家提醒：秋冬交替控制好这些因素</div>
                    <div class="text">“心梗是血管动脉粥样硬化形成斑块堵塞血管导致。”首都医科大学附属北京安贞医院急诊危重症中心副主任
                        医师段小春介绍，冠心病家族史、高血压、高血脂、高血糖、超重等都是损害血管、增加斑块不稳定性的危 险因素。</div>
                </div>
            </div>
        </div>
        <pagination :totalNum="12" class="pagiNation"></pagination>
    </div>
</template>
<script>
export default {
    data() {
        return {
            current: 0,//切换标识
            titleList: [//导航栏数据
                "企业动态",
                "行业资讯"
            ],

        };
    },
    methods: {
        changeIndex(index) {
            this.current = index
        },
        goToXinWenDetail(item){
            this.$router.push('/pcXinWenCenter/pcCenterDetail?id='+item)
        }
    }
}
</script>
<style lang="less" scoped>
.pagiNation{
    margin-bottom: 58Px;
}
.xinWenListContainer {
    width: 1728Px;
    background: #F7F7F7;
    box-sizing: border-box;
    padding: 40Px 228Px 58Px 228Px;
    margin: 0 auto;
    .top {
        padding: 0Px 510Px;
        margin-bottom: 48Px;

        .titleItem {
            // width: 96Px;
            height: 34Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
        }

        .titleItem:first-child {
            margin-right: 60Px;
        }
    }

    .List {
        margin-bottom: 40Px;

        .item {
            box-sizing: border-box;
            padding: 60Px 321Px 60Px 30Px;
            width: 1272Px;
            height: 240Px;
            background: #FFFFFF;
            border-radius: 8Px 8Px 8Px 8Px;
            opacity: 1;

            border: 1Px solid #BD0048;
            margin-bottom: 20Px;

            .left {
                .day {
                    height: 50Px;
                    font-size: 36Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 42Px;
                }

                .year {
                    height: 34Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28Px;
                }
            }

            .right {
                width: 754Px;

                .rightTitle {
                    height: 34Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 28Px;
                    margin-bottom: 20Px;
                }

                .text {
                    height: 66Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 19Px;
                }
            }
        }
    }
}

.active {
    /*激活样式*/
    color: #BD0048 !important;
    position: relative;
}

.active .activeLine {
    /*激活样式*/
    content: '';
    position: absolute;
    width: 80Px;
    height: 4Px;
    background: #BD0048;
    border-radius: 8Px 8Px 8Px 8Px;
    left: 50%;
    top: 40Px;
    transform: translate(-50%, -50%);

}
</style>