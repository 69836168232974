<template>
    <div>
        <mbHeader></mbHeader>
        <div class="imageContainer">
            <p class="bigTitle" style="font-family: PingFang SC-Bold;">做有温度的护理</p>
            <div class="line">

            </div>
            <div class="smallText">老有所养 老有所乐 老有所医 老有所为</div>
            <div class="button">咨询详情</div>
        </div>
        <div class="aboutUs">
            <p class="title">关于我们</p>
            <div class="line"></div>
            <p class="smallText">公司成立于2014年，由多家护理院共同组成，多年来致力于老人养老服务，通过丰富的经验，专业的职业素养，为老人提供全方位、专业化系统服务，现已在全国拥有医疗及养老床位逾4700张。
            </p>
            <div class="contact">联系我们</div>
        </div>
        <div class="longBaoXian">
            <p class="title">长期护理保险</p>
            <div class="line" style=" margin-bottom: 0.8rem;"></div>
            <p class="smallText">长期护理保险，破养老“痛点”，减轻老年人、 失能、失智者照护负担。42项生活照料和临床 护理服务，个人仅需承担10%的费用。花最少 的钱，得到更专业的照护体验。
            </p>
            <div class="shenQing">申请办理</div>
            <div class="line" style="margin-bottom: 0.533rem;"></div>
            <p class="shenQingText">本市年满60周岁及以上，拥有城镇医保或居民 医保，通过居住地所属居委会或街道社区事务受理服务中心就近办理。</p>
            <div class="shiYongRenQun">适用人群</div>
            <div class="line" style="margin-bottom: 0.533rem;"></div>
            <div class="renQunTable renQunTable1 " style="height: 6rem;">
                <div class="tr betweenFlex">
                    <div class="td">基本生活需协助(如穿 衣脱裤等)</div>
                    <div class="td">慢性病患者(如高血压、糖尿病等)</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">大小便不同程度失禁</div>
                    <div class="td">腿脚不便，户外 活动需要陪同</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">生活自理能力下降</div>
                    <div class="td">长期卧床</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">记忆轻/重度障碍</div>
                    <div class="td">理解与表达轻/重度障碍</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">听力与视力下降</div>
                    <div class="td"></div>
                </div>

            </div>
            <p class="shouLiShenQing">受理申请</p>
            <div class="line" style="margin-bottom: 0.533rem;"></div>
            <p class="shouLiText">全市通办，通过居住地所在居委会或街道社区事务受理服务中心就近办理。需要携带并提交以下资料。</p>
            <div class="smallImageContainer">
                <div class="imageItem columnBetweenFlex" style="  margin-right: .933rem;">
                    <img src="@/assets/mobile/Vector@2x(1).png" style=" width: 1.259rem;">
                    <div class="imageText">身份证(委托代办者， 需代办人身份证)</div>
                </div>
                <div class="imageItem columnBetweenFlex">
                    <img src="@/assets/mobile/Vector@2x(2).png" style="width: 1.249rem;">
                    <div class="imageText">社保卡(医保卡)</div>
                </div>
                <div class="imageItem columnBetweenFlex" style="  margin-right: .933rem;">
                    <img src="@/assets/mobile/Vector@2x(3).png" style="width: 1.008rem;">
                    <div class="imageText">填写《长护险评估表》</div>
                </div>
                <div class="imageItem columnBetweenFlex">
                    <img src="@/assets/mobile/Vector@2x(4).png" style="width: .966rem;">
                    <div class="imageText">其他相关资料</div>
                </div>
            </div>
            <div class="pingGuTitle">评估等级</div>
            <div class="line" style="margin-bottom: 0.533rem;"></div>
            <div class="pingGuText">评估机构会根据您的疾病情况、照护需求程度，确定照护等级。</div>
            <div class="zhuZhuangTu betweenFlex">
                <div class="zhuZhuangItem  columnBetweenFlex" style="left: .533rem;">
                    <div class="littleText">3小时/周</div>
                    <div class="red" style="height: 2.133rem;"></div>
                    <div class="redLine"></div>
                    <div class="bigText" style="width: 1.8rem;">二、三级</div>
                </div>
                <div class="zhuZhuangItem columnBetweenFlex" style="left: 3.6rem;">
                    <div class="littleText">5小时/周</div>
                    <div class="red" style="height: 3.2rem;"></div>
                    <div class="redLine"></div>
                    <div class="bigText" style="width: 0.953rem;">四级</div>
                </div>
                <div class="zhuZhuangItem columnBetweenFlex" style="left: 6.667rem;">
                    <div class="littleText">7小时/周</div>
                    <div class="red" style="height: 4.267rem;"></div>
                    <div class="redLine"></div>
                    <div class="bigText" style="width: 1.8rem;">五、六级</div>
                </div>
            </div>
            <div class="projectTitle">长护险服务项目</div>
            <div class="line" style="margin-bottom: 0.533rem;"></div>
            <div class="littleText">长护险基本生活照料（27项）</div>
            <!-- style="height: 16.8rem;" -->
            <table class="renQunTable renQunTable2" >
                <div class="tr betweenFlex">
                    <div class="td" >头/面部清洁、护理</div>
                    <div class="td">指/趾甲护理</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">温水擦浴</div>
                    <div class="td">协助进食/水</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">协助更衣</div>
                    <div class="td">排泄护理</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">床上使用便器</div>
                    <div class="td">晨间护理</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">会阴护理</div>
                    <div class="td">协助翻身扣背排痰</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">借助器具移动</div>
                    <div class="td">安全 护理</div>

                </div >
                <div class="tr betweenFlex">
                    <div class="td">压疮预防护理</div>
                    <div class="td">人工肛门便袋护理</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">洗发</div>
                    <div class="td">手、足部清洁</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">沐浴</div>
                    <div class="td">口腔清洁</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">整理床单位</div>
                    <div class="td">失衡护理</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">人工取便</div>
                    <div class="td">晚间护理</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">药物管理</div>
                    <div class="td">协助床上移动</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">留置尿管护理</div>
                    <div class="td">皮肤外用药擦涂</div>

                </div>
                <div class="tr betweenFlex">
                    <div  class="td">生活自理能力训练</div>
                    <div class="td"></div>
                </div>

            </table>
            <div class="littleText">长护险医疗护理（15项）</div>
            <table class="renQunTable renQunTable3 " style="height: 9.6rem;">
                <div class="tr betweenFlex">
                    <div class="td">开塞露/直肠栓剂给药</div>
                    <div class="td">药物服喂</div>

                </div>
                <div class="tr betweenFlex">
                    <div class="td">生命体征监测</div>
                    <div class="td">灌肠</div>
                </div>

                <div class="tr betweenFlex">
                    <div class="td">血糖监测</div>
                    <div class="td">静脉血标本采集</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">皮下注射</div>
                    <div class="td">经外周静脉置入中心 静脉导管(PICC)维护</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">鼻饲</div>
                    <div class="td">物理降温</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">吸氧</div>
                    <div class="td">导尿(女性)</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">压疮伤口换药</div>
                    <div class="td">肌肉注射</div>
                </div>
                <div class="tr betweenFlex">
                    <div class="td">造口护理</div>
                    <div class="td"></div>
                </div>
            </table>
            <div class="moneyTitle">关于费用</div>
            <div class="line" style="margin-bottom: 0.533rem;"></div>
            <div class="huGongText">护工或护理员上门自费部分仅6.5或8元/小时。</div>


            <div class="pieContainer" style="">
                <div class="pie88">
                    <div class="pie_content"></div>
                    <div class="pie_left"></div>
                    <div class="pie_right"></div>
                </div>
                
                <div class="pieText pieText1">10%由个人支付</div>
                <div class="pieText pieText2">90%由长护险基金支付</div>
            </div>
        </div>
        <div class="xinWenCenter">
            <img class="imgBackGround" src="@/assets/mobile/Rectangle 464@2x.png">
            <div class="container">
                <div class="title betweenFlex">
                    <div class="left">新闻中心</div>
                    <div class="right">更多</div>
                </div>
                <div class="line" style="margin-bottom: 1.067rem;"></div>
                <div class="topTuPian">
                    <img src="@/assets/mobile/Rectangle 440@2x.png" style="width: 8.933rem;height: 4.8rem;">
                    <div class="text betweenFlex">
                        <div class="left ">
                            <div class="bigText">11-16</div>
                            <div class="smallText">2023</div>
                        </div>
                        <div class="right">
                            科学家发现有望治疗胰腺癌 的新方法
                        </div>
                    </div>
                </div>
                <div class="bottomContent" v-for="item in 3">
                    <div class="bigTitle">11-06</div>
                    <div class="year">2023</div>
                    <div class="smallTitle">远离心梗 专家提醒：秋冬交替控制好这些因素</div>
                    <div class="littleText">“心梗是血管动脉粥样硬化形成 斑块堵塞血管导致。”</div>
                    <div class="jianTou">→</div>
                </div>
            </div>
        </div>
        <div class="fuWuZhanDian">
            <div class="title">服务站点</div>
            <div class="line" style="margin-bottom: 1.067rem;"></div>
            <div class="text">长护险定点服务医疗机构，为出入院患者、长期卧床患者及社区居家长者等被照护人群提供 生活照护和常用临时床照护等高品质医养服务。</div>
            <div class="fuWuImageContainer">
                <img src="@/assets/mobile/Rectangle 446@2x.png" class="img">
                <img src="@/assets/mobile/Rectangle 447@2x.png" class="img">
                <img src="@/assets/mobile/Rectangle 448@2x.png" class="img">
                <img src="@/assets/mobile/Rectangle 449@2x.png" class="img">
            </div>
        </div>
        <div class="contact2">
            <div class="contactTitle">联系方式</div>
            <div class="line" style="margin-bottom: .533rem;"></div>
            <div class="littleText">如果想进一步了解长护险，您可以通过以下 方式联系我们，我们将为您解答疑问。</div>
            <img src="@/assets/mobile/Rectangle 520@2x.png" class="contactImg">

        </div>
        <mbFooter></mbFooter>

    </div>
</div></template>

<script>

export default {

};
</script>

<style lang="less" scoped>

.contact2 {
    width: 10rem;
    height: 9.6rem;
    background: rgba(51, 51, 51, 0.25);
    background-image: url(@/assets/mobile/Rectangle 528@2x.png);
    padding-top: 1.333rem;
    padding-left: .533rem;
    background-size: 100% 100%;
    box-sizing: border-box;
    margin-bottom: 2.667rem;

    .contactTitle {
        width: 4, 53rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.013rem;
    }

    .littleText {
        width: 8.107rem;
        height: 1.173rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.507rem;
        margin-bottom: .533rem;
    }

    .contactImg {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 0.213rem;
    }
}

.fuWuZhanDian {
    width: 10rem;
    height: 17.067rem;
    background: #FFFFFF;
    padding-left: 0.533rem;
    box-sizing: border-box;
    padding-top: 1.333rem;

    .title {
        width: 4.5rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.013rem;

    }

    .text {
        width: 8.96rem;
        height: 1.76rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.507rem;
        margin-bottom: 1.067rem;
    }

    .fuWuImageContainer {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .8rem;

        .img {
            width: 4.267rem;
            height: 4.267rem;
            border-radius: 0.213rem;
            margin-bottom: .4rem;
        }

        .img:nth-child(1) {
            margin-right: .4rem;
        }

        .img:nth-child(3) {
            margin-right: .4rem;
        }
    }
}

.xinWenCenter {
    width: 10rem;
    height: 34.667rem;
    position: relative;

    box-sizing: border-box;

    .imgBackGround {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    .container {
        margin-left: 0.533rem;
        margin-right: 0.533rem;

        .title {

            padding-top: .533rem;
            padding-right: .4rem;
            margin-bottom: .4rem;

            .left {
                width: 5rem;
                height: 1.2rem;
                font-size: 0.853rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 1.013rem;
            }

            .right {
                width: 0.86rem;
                height: 0.587rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 0.507rem;
            }
        }

        .topTuPian {
            width: 8.933rem;
            height: 8rem;
            background: #FFFFFF;
            border-radius: 0.213rem;
            margin-bottom: 0.8rem;
            box-sizing: border-box;

            .text {
                .left {
                    width: 2.32rem;
                    margin: .533rem 0 0 .533rem;
                    height: 1.92rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .bigText {
                        width: 2.7rem;

                        height: 1.333rem;
                        font-size: 0.96rem;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 1.12rem;
                    }

                    .smallText {

                        width: 1.04rem;
                        height: 0.587rem;
                        font-size: 0.427rem;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 0.507rem;
                    }
                }

                .right {
                    width: 5.12rem;
                    height: 1.173rem;
                    font-size: 0.427rem;
                    box-sizing: border-box;
                    padding-right: .4rem;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 0.507rem;
                }
            }
        }

        .bottomContent {
            width: 8.933rem;
            height: 7.2rem;
            background: #FFFFFF;
            border-radius: 0.213rem;
            padding-top: 0.533rem;
            padding-left: 0.533rem;
            position: relative;
            box-sizing: border-box;

            .bigTitle {
                width: 2.707rem;
                height: 1.333rem;
                font-size: 0.96rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 1.12rem;
            }

            .year {
                width: 1.04rem;
                height: 0.587rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.507rem;
                margin-bottom: .533rem;
            }

            .smallTitle {
                width: 7.84rem;
                height: 1.173rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                color: #333333;
                line-height: 0.507rem;
                margin-bottom: .533rem;
            }

            .littleText {
                width: 5.787rem;
                height: 1.173rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 0.507rem;
            }

            .jianTou {
                width: 0.8rem;
                height: 0.053rem;
                color: #999999;
                position: absolute;
                bottom: 1rem;
            }
        }
    }


}

.pieContainer {
    margin: 1.067rem 2.027rem 1.333rem 3.653rem;
    padding: 0.533rem .56rem;
    box-sizing: border-box;
    position: relative;

    .border {
        top: .533rem;
        position: absolute;
        width: 2.667rem;
        height: 2.667rem;
        border-style: dashed;
        border-width: 0.013rem;
        border-color: #A2A2A2;
        z-index: 6;
    }

    .pieText {
        text-align: center;
        position: absolute;
        font-size: 0.32rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;

        height: 0.64rem;
        line-height: 0.64rem;
        background: rgba(51, 51, 51, 0.7);
        border-radius: 0.107rem;
    }

    .pieText1 {
        width: 2.667rem;
        top: 28%;
        left: 2.347rem;
    }

    .pieText2 {
        width: 3.733rem;
        bottom: 35%;
        right: 2.347rem;
    }
}


.pie88 {
    width: 2.667rem;
    height: 2.667rem;
    border-radius: 1.333rem;
    background-color: #BD0048;
    position: relative;
    display: inline-block;
    overflow: hidden;

    .pie_left {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.333rem;
        height: 2.667rem;
        overflow: hidden;
        background-color: #2A7EFB;
    }

    .pie_right {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.333rem;
        height: 2.667rem;
        overflow: hidden;

    }

    .pie_right::after {
        content: "";
        height: 2.667rem;
        width: 1.333rem;
        border-left: 1.333rem solid #2A7EFB;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 1.333rem;
        transform: rotate(-137deg);
    }
}

.line {
    width: 2.667rem;
    height: 0.107rem;
    background: #BD0048;
    border-radius: 0.213rem 0.213rem 0.213rem 0.213rem;

}


.longBaoXian {
    box-sizing: border-box;
    width: 10rem;
    // height: 86.027rem;
    height: 84.533rem;
    padding-top: 1.333rem;
    padding-left: 0.533rem;


    .renQunTable {

        width: 8.933rem;
        background: #F7F7F7;
        

        margin-bottom: 1.067rem;

        border: .027rem solid #999999;

        .tr {
            width: 100%;
           
            .td {
                width: 50%;
                height: 100%;
                text-align: center;
                font-size: 0.32rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0rem .747rem;
                box-sizing: border-box;
                border-bottom: .027rem solid #999999;
                border-right: .027rem solid #999999;
            }

            .td:last-child {
                border-right: none;
            }
        }

        .tr:last-child {
            .td {
                border-bottom: none;
            }
        }


    }

    .renQunTable1 {

        
        .tr {
            height: 20%;
        }

    }

    .renQunTable2 {

        
        .tr {
            height: 1.2rem;
        }


    }

    .renQunTable3 {
        .tr {
            height: 12.5%;
        }


    }

    .title {
        width: 7rem;
        height: 1.333rem;
        font-size: 0.96rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.12rem;
    }



    .smallText {

        width: 9rem;
        height: 2.347rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.507rem;
        margin-bottom: 1.067rem;
    }

    .shenQing {
        width: 4.53rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.013rem;
    }


    .shenQingText {
        width: 8.64rem;
        height: 1.76rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.507rem;
        margin-bottom: .8rem;
    }

    .shiYongRenQun {
        width: 2.56rem;
        height: 0.907rem;
        font-size: 0.64rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.747rem;
    }



    .shouLiShenQing {
        width: 4.53rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.013rem;
    }

    .shouLiText {
        width: 8.633rem;
        height: 1.76rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.507rem;
        margin-bottom: .8rem;
    }

    .smallImageContainer {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .667rem;

        .imageItem {
            width: 4rem;

            margin-bottom: .4rem;

            img {

                height: 1.067rem;
                margin-bottom: .267rem;
            }

            .imageText {
                width: 4rem;
                height: 1.173rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.507rem;
                text-align: center;
            }
        }
    }

    .pingGuTitle {
        width: 4.53rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.013rem;
    }

    .pingGuText {
        width: 9rem;
        height: 1.173rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.507rem;
    }

    .zhuZhuangTu {
        padding-left: 0.533rem;
        padding-right: 1.067rem;
        height: 7.84rem;
        position: relative;
        margin-bottom: 1.067rem;

        .zhuZhuangItem {
            position: absolute;
            bottom: 0;

            .littleText {
                width: 1.76rem;
                height: 0.587rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.507rem;
            }

            .red {
                width: 1.067rem;

                background: #BD0048;
                margin: 0.267rem .32rem;
            }

            .redLine {
                width: 1.067rem;
                height: 0.053rem;
                background: #BD0048;
                margin-bottom: .533rem;
            }

            .bigText {
                text-align: center;
                height: 0.587rem;
                font-size: 0.427rem;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.507rem;
            }
        }
    }

    .projectTitle {
        width: 7rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.013rem;
    }

    .littleText {
        width: 5.627rem;
        height: 0.587rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.507rem;
        margin-bottom: 0.533rem;
    }

    .moneyTitle {
        width: 4.5rem;
        height: 1.2rem;
        font-size: 0.853rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 1.013rem;
    }

    .huGongText {
        width: 8.773rem;
        height: 0.587rem;
        font-size: 0.427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 0.507rem;
    }

}


.imageContainer {
    width: 10rem;
    box-sizing: border-box;
    height: 7.04rem;
    background-image: url(../../assets/mobile/Rectangle 461@2x.png);
    padding-left: .533rem;

    .bigTitle {
        padding-top: 1.2rem;
        font-size: .96rem;
        font-weight: bold;
        color: #FFFFFF;
        height: 1.33333rem;
        margin-bottom: .267rem;
    }

    .line {

        margin-bottom: 0.533rem;
    }

    .smallText {
        font-size: .427rem;

        color: #FFFFFF;
        line-height: 0.507rem;

        font-weight: 400;
        margin-bottom: 1.2rem;
        font-family: PingFang SC, PingFang SC;

    }

    .button {
        width: 3.2rem;
        height: 0.853rem;
        background: #BD0048;
        border-radius: 0.107rem 0.107rem 0.107rem 0.107rem;
        font-size: 0.427rem;
        text-align: center;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.853rem;
    }
}

.aboutUs {

    width: 10rem;
    height: 9.6rem;
    background: #F7F7F7;
    padding-left: 0.533rem;
    box-sizing: border-box;

    .title {
        font-size: 0.96rem;
        font-weight: 400;
        color: #333333;
        padding-top: 1.067rem;
        line-height: 1.333rem;
        font-family: PingFang SC, PingFang SC;
    }

    .line {
        margin-bottom: .8rem;

    }

    .smallText {
        font-size: 0.427rem;
        font-weight: 400;
        color: #333333;
        line-height: 0.507rem;
        margin-bottom: 1.067rem;
        padding-right: .7rem;

        word-break: break-all;
        font-family: PingFang SC, PingFang SC;
    }

    .contact {
        width: 3.2rem;
        height: 0.853rem;
        background: #FFFFFF;
        border-radius: 0.213rem 0.213rem 0.213rem 0.213rem;
        text-align: center;
        line-height: 0.853rem;
        font-weight: 400;
        font-size: 0.427rem;
        border: 0.027rem solid #BD0048;
        font-family: PingFang SC, PingFang SC;
    }
}</style>