<template>
    <div class="betweenFlex" style="padding: 0.267rem .533rem;position: relative;">
        <div class="startFlex">
            <img src="@/assets/mobile/wesite-logo 1@2x.png" style="width: .747rem;height: .64rem;margin-right: .32rem;">
            <p style="font-size: 0.427rem;">广州暖善社区居家养老服务有限公司</p>
        </div>
        <div class="columnBetweenFlex" @click="show">
            <div class="hengTiao" v-for="item in 3" v-show="!showXuanXiang">

            </div>

            <img src="@/assets/mobile/close.png" class="close" v-show="showXuanXiang">

        </div>
        <div class="XuanXiang" v-show="showXuanXiang">
            <p class="XuanXiangItem" v-for="item, index in List" @click="goToDetail(index)">{{ item }}</p>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            showXuanXiang: false,
            List: [
                "首页",
                "新闻中心",
                "服务中心",
                "联系方式"
            ]
        };
    },

    mounted() {

    },

    methods: {
        show() {
            this.showXuanXiang = !this.showXuanXiang
        },
        goToDetail(index) {
            switch (index) {
                case 0:
                    this.$router.push('/mbHome')
                    this.showXuanXiang = false
                    break;
                case 1:
                    this.$router.push('/mbXinWenCenter')
                    this.showXuanXiang = false
                    break;
                case 2:
                    // this.$router.push('/mbFuWuCenter')
                    this.showXuanXiang = false
                    break;
                case 3:
                    // this.$router.push('/mbContact')
                    this.showXuanXiang = false
                    break;

            }

        }
    },
};
</script>

<style lang="less" scoped>
.hengTiao {
    width: 0.64rem;
    height: 0.107rem;
    background: #333333;
    border-radius: 0.213rem 0.213rem 0.213rem 0.213rem;
    margin-bottom: 0.107rem;
}

.hengTiao:last-child {
    margin-bottom: 0;
}

.close {
    width: .48rem;
    height: .48rem;
}

.XuanXiang {
    width: 6.4rem;
    height: 7.627rem;
    background: #FFFFFF;
    padding: .8rem .533rem;
    box-sizing: border-box;
    position: absolute;
    top: 1.1rem;
    right: 0;
    z-index: 99;
    .XuanXiangItem {
        width: 6.4rem;
        height: 0.907rem;
        font-size: 0.64rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.747rem;
        margin-bottom: .8rem;
    }

    .XuanXiangItem:last-child {
        margin-bottom: 0;
    }
}
</style>