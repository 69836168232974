<template>
    <div class="detailContainer">
        <div class="grayContainer">
            <div class="grayItem ">企业资讯</div>
            <div class="grayItem">></div>
            <div class="grayItem">远离心梗 专家提醒：秋冬交替 控制好这些风险因素</div>
        </div>
        <div class="contentContainer">
            <div class="topTitleContainer">
                <div class="title">远离心梗 专家提醒：秋冬 交替控制好这些风险因素</div>
                <div class="day">2023/11/16</div>
            </div>
            <div class="detailBottom">
                <div class="detailContent">
                    <div class="detailLong">据媒体报道，著名诗人、散文家、首届
                        鲁迅文学奖获得者周涛先生，于11月4日
                        13时30分因突发心梗在乌鲁木齐去世。
                        秋冬季节交替，正是心血管疾病的高发
                        期。据统计，在全球疾病死亡原因中，
                        肿瘤排第一，心梗排第二。遇到心梗突
                        发，我们能做些什么？

                        出现这些情况最好去医院检查

                        “心梗是血管动脉粥样硬化形成斑块堵塞
                        血管导致。”首都医科大学附属北京安贞
                        医院急诊危重症中心副主任医师段小春
                        介绍，冠心病家族史、高血压、高血脂、
                        高血糖、超重等都是损害血管、增加斑块
                        不稳定性的危险因素。年轻心梗患者增加，
                        可能和生活不规律、经常熬夜、身体处于
                        极度兴奋或疲惫的状态、饮食不健康、抽
                        烟等因素相关。另外，情绪、悲伤、抑郁、
                        过度高兴也会导致心脏损伤，甚至引发心
                        梗。此外，心梗的发作还与气温变化有一
                        定关系。
                        心梗的临床表现多种多样。段小春介绍，
                        最典型的就是因心脏血管梗塞，心肌细胞
                        缺血坏死，心前区或左侧胸部出现一种剧
                        烈的疼痛或闷痛，部分人疼痛感可放射至
                        后背、左侧肩部、脖子、下颌、左上肢。
                        部分人疼痛表现不典型，心前区疼痛不明
                        显，而是有明显的牙疼、上腹部疼痛或脖
                        子疼。一些患者情况危重，可能出现急性
                        心衰，表现为喘憋、满头大汗、血压下降；
                        有些人还会心率变慢、头晕、眼花、昏厥；
                        也有人会表现为心率特别快，甚至出现心
                        律失常。
                        如果救治不及时，会出现心衰、并发症，
                        后续也会因心脏功能的改变影响身体健康。
                        “心梗是一个突发疾病，先兆情况不好把
                        握。”段小春提示，患有高血压、高血脂、
                        高血糖，或近期有熬夜、休息不好、精神
                        压力大等情况，如果出现心脏不舒服，如
                        心慌、憋气，特别是活动以后感觉这种症
                        状更加明显，需要重视起来，最好前往医
                        院检查，如有问题可及早处理。 
                        及时拨打120，勿自行前往医院

                        发生心梗，在血管堵塞的30分钟之内，开
                        始出现心肌细胞坏死，心肌细胞坏死是不
                        可再生的，当心梗一直持续6-8小时，心
                        肌细胞就会完全坏死。“越早前往医院，
                        越早打通血管，越早恢复血流，就能挽救
                        更多的心肌细胞，改善心脏功能和预后，
                        也能减少心梗对心脏和身体造成的危害。”
                        段小春强调，如果怀疑发生了心梗一定要
                        拨打120，部分患者发生心梗后会自行前
                        往医院，无论是开车还是步行都会活动，
                        都可能对心脏造成负担，增加风险，导致
                        病情加重。
                        日常生活中，预防心梗，要了解自己的家
                        族史，一级亲属有没有患冠心病、高血压、
                        糖尿病、高血脂、心梗的情况。血脂高，
                        特别是遗传性高胆固醇血症，患冠心病、
                        心梗几率较其他人群相对要高。段小春指
                        出，遗传因素无法改变，患者可以积极控
                        制一些风险因素，如关注空腹血糖、餐后
                        血糖、糖化血红蛋白、低密度脂蛋白、总
                        胆固醇、甘油三酯等指标，将指标控制在
                        合理范围之内。此外，还要控制自身腰围、
                        养成良好饮食习惯、养成规律运动的习惯
                        等。
                        研究已经表明，天气变化和心梗是相关的，
                        尤其是秋冬季节寒潮来临或突然降温。段
                        小春提醒，天气寒冷，北方室内有供暖比
                        较暖和，室内外温差较大，出门要做好保
                        暖措施，如戴好帽子、手套、口罩、围巾
                        等，以免受到寒冷刺激。当从寒冷的环境
                        中进入暖和的室内时，要给自己一个适应
                        过程，比如不要一下子将厚衣服全部脱掉，
                        最好一件一件衣服慢慢减少。特别是有晨
                        练习惯的老年朋友和有心血管疾病的患者，
                        不要在早上偏寒冷的时候出门锻炼，最好
                        选择中午天气暖和的时间段再出门。</div>
                    <div class="detailTitle detailTitle1">上一篇：远离心梗 专家提醒：秋冬交替 控制好这些风险因素</div>
                    <div class="detailTitle">下一篇：远离心梗 专家提醒：秋冬交替 控制好这些风险因素</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.detailContainer {
    background: #F7F7F7;
    box-sizing: border-box;
    padding: 20px 20px 60px 20px;

    .grayContainer {
        width: 310px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 19px;
        margin-bottom: 30px;

        .grayItem {
            margin-right: 10px;
        }

        .grayItem:first-child {
            width: 64px;
            height: 22px;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 19px;
        }

        .grayItem:nth-child(2) {
            width: 12px;
            height: 12px;
        }

        .grayItem:last-child {
            width: 214px;
            height: 44px;
            margin-right: 0;
        }
    }

    .contentContainer {
        width: 335px;
        // height: 2140px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;

        .topTitleContainer {
            box-sizing: border-box;
            padding: 20px 43px 20px 20px;
            border-bottom: 1px solid #D9D9D9;

            .title {
                width: 272px;
                height: 68px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                font-size: 24px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 32px;
                margin-bottom: 12px;
            }

            .day {
                width: 84px;
                height: 22px;
                font-size: 16px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 19px;
            }
        }

        .detailBottom {
            box-sizing: border-box;
            padding: 20px 10px 35px 20px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            width: 334px;

            .detailContent {
                width: 304px;

                .detailLong {
                    margin-bottom: 30px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 25px;
                    width: 304px;
                }

                .detailTitle {
                    height: 44px;
                    font-size: 16px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                }

                .detailTitle1 {
                    margin-bottom: 20px;
                }
            }
        }
    }
}
</style>