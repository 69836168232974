<template lang="">
    <div class="footer">
            <div class="content">
                ©2015-2026 ehooton.com版权所有 ICP证:粤ICP备15004092号 | 粤ICP备15004092号-6 | 增值电信业务经营许可证：粤B2-20230950 |
                公司主体:广州暖善社区居家养老服务有限公司 联系电话: 400-6313-299 | 员工舞弊举报: hr.tan@tianheyihu.com
            </div>
        </div>
</template>
<script>
export default {

}
</script>
<style lang="less">
.footer {
    width: 1728Px;
    height: 100Px;
    box-sizing: border-box;
    background: #1B1717;
    padding: 10Px 376Px;
    margin: 0 auto;

    .content {
        width: 975Px;
        height: 44Px;
        font-size: 16Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 45Px;
    }
}
</style>