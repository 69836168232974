<template lang="">
    <div class="bottom">
            <div class="text">
                <p>©2015-2026 ehooton.com版权所有</p>
                <p>ICP证:粤ICP备15004092号 |</p>
                <p>粤ICP备15004092号-6 | </p>
                <p>增值电信业务经营许可证：粤B2-20230950 </p>
                <p>|公司主体:广州暖善社区居家养老服务有限公司</p>
                <p>联系电话: 400-6313-299 | </p>
                <p>员工舞弊举报: hr.tan@tianheyihu.com</p>
            </div>
        </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less">
    .bottom {
    width: 10rem;
    height: 5.333rem;
    background: #1B1717;
    padding: .613rem .453rem .613rem .533rem;
    box-sizing: border-box;

    .text {
        width: 9.013rem;
        height: 4.107rem;

        font-size: .427rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: .59rem;
    }
}

</style>