<template >
    <div>
        <mbHeader></mbHeader>
        <div class="topImageContainer">
            <div class="title">新闻中心</div>
            <div class="line"></div>
            <div class="littleText">了解最新的医疗行业新闻和公司动态</div>
        </div>
        <router-view></router-view>
        <mbFooter></mbFooter>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="less" scoped>
.topImageContainer {

    width: 10rem;
    height: 4.8rem;
    background-size: 100%;
    box-sizing: border-box;
    padding-top: 1.28rem;
    padding-left: 0.533rem;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(51, 51, 51, 0.25)), url('@/assets/mobile/xinWen/Rectangle 505@2x.png');

    .title {
        height: 0.907rem;
        font-size: 0.64rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 0.747frem;
        margin-bottom: 0.267rem;
    }

    .line {
        width: 1.6rem;
        height: 0.107rem;
        background: #BD0048;
        border-radius: 0.213rem;
        margin-bottom: 0.533rem;
    }

    .littleText {
        height: 0.453rem;
        font-size: 0.32rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 0.373rem;
    }

}
</style>