<template>
    <div class="allContainer" style="">
       
        <pcHeader current="0"></pcHeader>
        <div class="topContainer">
            <div class="bigTitle">做有温度的护理</div>
            <div class="outLine"></div>
            <div class="littleText">
                老有所养 老有所乐 老有所医 老有所为
            </div>
            <div class="detail">咨询详情</div>


        </div>
        <div class="aboutUsContainer betweenFlex">
            <div class="left">
                <div class="bigTitle">关于我们</div>
                <div class="outLine"></div>
                <div class="littleTitle">公司成立于2014年，由多家护理院共同组成，多年来致力于 老人养老服务，通过丰富的经验，专业的职业素养，为老人 提供全方位、专业化系统服务，现已在全国拥有医疗及养老
                    床位逾4700张。</div>
                <div class="button">联系我们</div>
            </div>
            <div class="right">
                <img src="@/assets/pc/Rectangle 430@2x.png" style="">
            </div>
        </div>
        <div class="longBaoXianContainer">

            <div class="rightImg columnBetweenFlex">
                <div class="rightItem"><img src="@/assets/pc/Vector@2x(2).png" class="img"></div>
                <div class="rightItem"><img src="@/assets/pc/Vector@2x(1).png" class="img"></div>
            </div>
            <div class="bigTitle">长期护理保险</div>
            <div class="outLine"></div>
            <div class="littleText">长期护理保险，破养老“痛点”，减轻老年人、失能、失智者照护负担。 42项生活照料和临床护理服务，个人仅需承担10%的费用。花最少的钱， 得到更专业的照护体验。</div>
            <div class="shenQingContainer">
                <div class="bigTitle">申请办理</div>
                <div class="inLine"></div>
                <div class="littleText">本市年满60周岁及以上，拥有城镇医保或居民医保， 通过居住地所属居委会或街道社区事务受理服务中心 就近办理。</div>
                <div class="littleRenQun">适用人群</div>
                <div class="inLine"></div>
                <table class="renQunTable1 ">
                    <tr class="first">
                        <td>基本生活需协助(如穿 衣脱裤等)</td>
                        <td>慢性病患者(如高血压、 糖尿病等)</td>
                        <td>大小便不同 程度失禁</td>
                    </tr>
                    <tr>
                        <td>腿脚不便，户外 活动需要陪同</td>
                        <td>生活自理能力下降</td>
                        <td>长期卧床</td>
                    </tr>
                    <tr>
                        <td>记忆轻/重度障碍</td>
                        <td>理解与表达轻/重度障碍</td>
                        <td>听力与视力下降</td>
                    </tr>
                </table>
                <div class="bigTitle">受理申请</div>
                <div class="inLine"></div>
                <div class="shouliText">全市通办，通过居住地所在居委会或街道社区事务 受理服务中心就近办理。需要携带并提交以下资料。</div>
                <div class="flexContainer ">
                    <div class="flexItem columnBetweenFlex">
                        <img src="@/assets/pc/Vector@2x(3).png" alt="" class="img">
                        <div class="flexText">身份证(委托代办者，需代办人身份证)</div>
                    </div>
                    <div class="flexItem columnBetweenFlex">
                        <img src="@/assets/pc/Vector@2x(4).png" alt="" class="img">
                        <div class="flexText">社保卡(医保卡)</div>
                    </div>
                    <div class="flexItem columnBetweenFlex">
                        <img src="@/assets/pc/Vector@2x(5).png" alt="" class="img">
                        <div class="flexText">填写 《长护险评估表》</div>
                    </div>
                    <div class="flexItem columnBetweenFlex">
                        <img src="@/assets/pc/Vector@2x(6).png" alt="" class="img">
                        <div class="flexText">其他相关资料</div>
                    </div>
                </div>
                <div class="bigTitle">评估等级</div>
                <div class="inLine"></div>
                <div class="dengjiText">评估机构会根据您的疾病情况、照护需求程度， 确定照护等级。</div>
                <div class="zhuZhuangTu betweenFlex">
                    <div class="zhuZhuangItem  columnBetweenFlex zhuZhuangItem1" style="">
                        <div class="zhuZhuangLittleText">3小时/周</div>
                        <div class="red" style=""></div>
                        <div class="redLine"></div>
                        <div class="bigText">二、三级</div>
                    </div>
                    <div class="zhuZhuangItem columnBetweenFlex zhuZhuangItem2" style="">
                        <div class="zhuZhuangLittleText">5小时/周</div>
                        <div class="red" style=""></div>
                        <div class="redLine"></div>
                        <div class="bigText">四级</div>
                    </div>
                    <div class="zhuZhuangItem columnBetweenFlex zhuZhuangItem3" style="">
                        <div class="zhuZhuangLittleText">7小时/周</div>
                        <div class="red"></div>
                        <div class="redLine"></div>
                        <div class="bigText">五、六级</div>
                    </div>
                </div>
                <div class="bigTitleFuWu">长护险服务项目</div>
                <div class="inLine"></div>
                <div class="changHuXianText">长护险基本生活照料（27项）</div>
                <table class="renQunTable2 ">
                    <tr class="first">
                        <td>头/面部清洁、护理</td>
                        <td>指/趾甲护理</td>
                        <td>温水擦浴</td>
                        <td>协助进食/水</td>
                        <td>协助更衣 </td>
                    </tr>
                    <tr>
                        <td>排泄护理</td>
                        <td>床上使用便器</td>
                        <td>晨间护理</td>
                        <td>会阴护理</td>
                        <td>协助翻身扣背排痰</td>
                    </tr>
                    <tr>
                        <td>借助器具移动</td>
                        <td>安全护理</td>
                        <td>压疮预防护理</td>
                        <td>人工肛门便袋护理</td>
                        <td>洗发</td>
                    </tr>
                    <tr>
                        <td>手、足部清洁</td>
                        <td>沐浴</td>
                        <td>口腔清洁</td>
                        <td>整理床单位</td>
                        <td>失衡护理</td>
                    </tr>
                    <tr>
                        <td>人工取便</td>
                        <td>晚间护理</td>
                        <td>药物管理</td>
                        <td>协助床上移动</td>
                        <td>皮肤外用药擦涂</td>
                    </tr>
                    <tr>
                        <td>生活自理能力训练</td>
                        <td>留置尿管护理</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
                <div class="changHuXianText changHuXianText2">长护险医疗护理（15项）</div>
                <table class="renQunTable3 ">
                    <tr class="first">
                        <td>开塞露/直肠栓剂给药</td>
                        <td>药物服喂</td>
                        <td>生命体征监测</td>
                        <td>灌肠</td>
                        <td>血糖监测</td>
                    </tr>
                    <tr>
                        <td>静脉血标本采集</td>
                        <td>皮下注射</td>
                        <td>经外周静脉置入中心 静脉导管(PICC)维护</td>
                        <td>鼻饲</td>
                        <td>物理降温</td>
                    </tr>
                    <tr>
                        <td>吸氧</td>
                        <td>导尿(女性)</td>
                        <td>压疮伤口换药</td>
                        <td>肌肉注射</td>
                        <td>造口护理</td>
                    </tr>

                </table>
                <div class="bigTitle bigTitle3" style="">关于费用</div>
                <div class="inLine"></div>
                <div class="feiYongText">护工或护理员上门自费部分仅6.5或8元/小时</div>
                <div class="pieContainer" style="">
                    <div class="pie88">
                        <div class="pie_content"></div>
                        <div class="pie_left"></div>
                        <div class="pie_right"></div>
                    </div>
                    <!-- <div class="border" style=" "></div>
                    <div class="border" style="  transform: rotate(-137deg);"></div> -->
                    <div class="pieText pieText1">10%由个人支付</div>
                    <div class="pieText pieText2">90%由长护险基金支付</div>
                </div>
            </div>
        </div>
        <div class="xinWenCenter">
            <div class="xinWenTitle">
                新闻中心
            </div>
            <div class="outLine"></div>
            <div class="xinWenImageContainer betweenFlex ">
                <div class="xinWenleftContainer columnBetweenFlex">
                    <img src="@/assets/pc/Rectangle 440@2x.png">
                    <div class="xLeftBottom betweenFlex">
                        <div class="day ">
                            <div class="big">11-16</div>
                            <div class="small">2023</div>
                        </div>
                        <div class="text">
                            科学家发现有望治疗 胰腺癌的新方法
                        </div>
                    </div>
                </div>
                <div class="xinWenrightContainer">
                    <div class="xinWenrightItem" v-for="xinWenItem,xinWenIndex in 3">
                        <div class="rightTitle">11-06</div>
                        <div class="rightDay">2023</div>
                        <div class="rightBigText">远离心梗 专家提醒： 秋冬交替控制好这些 因素</div>
                        <div class="smallText">“心梗是血管动脉粥样硬化形成 斑块堵塞血管导致。”</div>
                        <div class="littleJianTou">→</div>
                    </div>
                    
                    <div class="more">更多 ></div>
                </div>
            </div>
        </div>
        <div class="fuWuZhanDian">
            <div class="fuWuTitle">服务站点</div>
            <div class="outLine"></div>
            <div class="fuWuText">长护险定点服务医疗机构，为出入院患者、长期卧床患者及社区居家长者等 被照护人群提供生活照护和常用临时床照护等高品质医养服务。</div>
            <div class="fuWuContainer">
                <img src="@/assets/pc/Rectangle 446@2x.png" alt="" class="fuWuImage">
                <img src="@/assets/pc/Rectangle 447@2x.png" alt="" class="fuWuImage">
                <img src="@/assets/pc/Rectangle 448@2x.png" alt="" class="fuWuImage">
                <img src="@/assets/pc/Rectangle 449@2x.png" alt="" class="fuWuImage">
            </div>
        </div>
        <div class="contact">
            <div class="lianXiTitle">联系方式</div>
            <div class="outLine"></div>
            <div class="lianXiText">
                如果想进一步了解长护险，您可以通过以下方式联系我们，我们将为您解答疑问。
            </div>
            <img src="@/assets/pc/Rectangle 520@2x.png" alt="" class="lianXiImage">
        </div>
        <pcFooter></pcFooter>
    </div>
</template>

<script>
export default {
    name: 'pcHome',

    data() {
        return {
            current: 0,//切换标识
            nav: [//导航栏数据
                '首页',
                '新闻中心',
                '服务站点',
                '联系方式'
            ]
        };
    },

    mounted() {

    },

    methods: {
        // 导航栏切换
        go(index) {
            this.current = index//激活样式
            switch (index) {
                case 0:
                    this.$router.push('/pcHome')
                   
                    break;
                case 1:
                    this.$router.push('/pcXinWenCenter')
                    
                    break;
                case 2:
                    // this.$router.push('/mbFuWuCenter')
                   
                    break;
                case 3:
                    // this.$router.push('/mbContact')
                    
                    break;

            }
        }
    },
};
</script>

<style lang="less" scoped>


.contact {
    width: 1728Px;
    height: 720Px;
    background: rgba(51, 51, 51, 0.25);
    box-sizing: border-box;
    padding-left: 176Px;
    padding-top: 100Px;
    background-size: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(51, 51, 51, 0.25)), url('@/assets/pc/Rectangle 517@2x.png');
    margin-bottom: 100Px;
    margin:  0 auto ;

    .lianXiTitle {
        width: 256Px;
        height: 90Px;
        font-size: 64Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 75Px;
    }

    .lianXiText {
        width: 1152Px;
        height: 45Px;
        font-size: 32Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38Px;
        margin-bottom: 50Px;
    }

    .lianXiImage {
        width: 300Px;
        height: 300Px;
        border-radius: 8Px;
    }
}

.fuWuZhanDian {
    width: 1728Px;
    height: 794Px;
    background: #F7F7F7;
    padding-top: 116Px;
    padding-left: 180Px;
    box-sizing: border-box;

    .fuWuTitle {
        width: 256Px;
        height: 90Px;
        font-size: 64Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 75Px;
    }

    .fuWuText {
        width: 1056Px;
        height: 90Px;
        font-size: 32Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 38Px;
        margin-bottom: 40Px;
    }

    .fuWuContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .fuWuImage {
            width: 300Px;
            height: 300Px;
            border-radius: 8Px;
            margin-right: 41Px;
        }

        .fuWuImage:last-child {
            margin-right: 0;
        }
    }
}

.xinWenCenter {
    width: 1728Px;
    height: 720Px;
    background-size: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(51, 51, 51, 0.25)), url('@/assets/pc/Rectangle 433@2x.png');
    padding-top: 100Px;
    padding-left: 228Px;
    padding-right: 240Px;
    box-sizing: border-box;

    .xinWenTitle {
        width: 256Px;
        height: 90Px;
        font-size: 64Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 75Px;
    }

    .xinWenImageContainer {

        .xinWenleftContainer {
            width: 360Px;
            height: 400Px;
            background: #FFFFFF;
            border-radius: 8Px 8Px 8Px 8Px;
            margin-right: 60Px;

            img {
                width: 360Px;
                height: 280Px;
                border-radius: 8Px 8Px 0 0;
            }

            .xLeftBottom {
                box-sizing: border-box;
                padding: 24Px 20Px;


                .day {
                    // width:137Px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .big {
                        width: 107Px;
                        height: 50Px;
                        font-size: 36Px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 42Px;
                    }

                    .small {
                        width: 39Px;
                        height: 22Px;
                        font-size: 16Px;
                        font-family: PingFang SC, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 19Px;

                    }
                }
            }

            .text {
                width: 216Px;
                height: 68Px;
                font-size: 24Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 32Px;
                margin-left: 10Px;
            }

        }

        .xinWenrightContainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;

            .more {
                position: absolute;
                width: 78Px;
                height: 34Px;
                font-size: 24Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 28Px;
                top: -55Px;
                right: 0Px;
                
            }

            .xinWenrightItem:nth-child(1) {
                width: 280Px;
                height: 400Px;
                background: #FFFFFF;
                border-radius: 8Px 0Px 0Px 8Px;
                box-sizing: border-box;
                padding-left: 30Px;
                padding-top: 60Px;

                .rightTitle {
                    width: 94Px;
                    height: 50Px;
                    font-size: 33Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 42Px;
                }

                .rightDay {
                    width: 39Px;
                    height: 22Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 19Px;
                    margin-bottom: 18Px;
                }

                .rightBigText {
                    width: 224Px;
                    height: 102Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 32Px;
                    margin-bottom: 20Px;
                }

                .smallText {
                    width: 217Px;
                    height: 44Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 22Px;
                    margin-bottom: 30Px;
                }

                .littleJianTou {
                    width: 30Px;
                    height: 0.5Px;
                }
            }

            .xinWenrightItem:nth-child(2) {
                width: 280Px;
                height: 400Px;
                background: #BD0048;
                border-radius: 0Px 0Px 0Px 0Px;
                box-sizing: border-box;
                padding-left: 28Px;
                padding-top: 60Px;

                .rightTitle {

                    width: 94Px;
                    height: 50Px;
                    font-size: 33Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 42Px;
                }

                .rightDay {
                    width: 39Px;
                    height: 22Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 19Px;
                    margin-bottom: 18Px;
                }

                .rightBigText {

                    width: 224Px;
                    height: 102Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 32Px;
                    margin-bottom: 20Px;
                }

                .smallText {
                    width: 217Px;
                    height: 44Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 22Px;
                    margin-bottom: 30Px;

                }

                .littleJianTou {
                    width: 30Px;
                    height: 0.5Px;
                    color: #FFFFFF;
                }
            }

            .xinWenrightItem:nth-child(3) {
                width: 280Px;
                height: 400Px;
                background: #FFFFFF;
                border-radius: 0Px 8Px 8Px 0Px;
                box-sizing: border-box;
                padding-left: 28Px;
                padding-top: 60Px;

                .rightTitle {

                    width: 94Px;
                    height: 50Px;
                    font-size: 33Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 42Px;
                }

                .rightDay {
                    width: 39Px;
                    height: 22Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 19Px;
                    margin-bottom: 18Px;
                }

                .rightBigText {

                    width: 224Px;
                    height: 102Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                    line-height: 32Px;
                    margin-bottom: 20Px;
                }

                .smallText {
                    width: 217Px;
                    height: 44Px;
                    font-size: 16Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 22Px;
                    margin-bottom: 30Px;

                }

                .littleJianTou {
                    width: 30Px;
                    height: 0.5Px;
                    color: #666666;
                }
            }
        }
    }
}

.active {
    /*激活样式*/
    color: #BD0048 !important;
    position: relative;
}

.active .activeLine {
    /*激活样式*/
    content: '';
    position: absolute;
    width: 24Px;
    height: 4Px;
    left: 50%;

    top: 42Px;
    transform: translate(-50%, -50%);
    background: #BD0048;
    border-radius: 8Px 8Px 8Px 8Px;
}



.bigTitle3 {
    width: 192Px;
}

.changHuXianText2 {
    width: 369Px;
}

.inLine {
    width: 100Px;
    height: 4Px;
    background: #BD0048;
    border-radius: 8Px 8Px 8Px 8Px;
    margin-top: 10Px;
    margin-bottom: 30Px;
}

.longBaoXianContainer {
    width: 1728Px;
    // height: 4189Px;

    background: #F7F7F7;
    box-sizing: border-box;
    padding-left: 228Px;
    padding-right: 229Px;
    padding-top: 115Px;
    position: relative;
    padding-bottom: 100Px;



    .rightImg {
        position: absolute;
        top: 299Px;
        right: 60Px;
        width: 60Px;
        height: 130Px;


        .rightItem {
            border-radius: 8Px;
            width: 60Px;
            height: 60Px;
            background: #FFFFFF;
            border: 1Px solid #BD0048;
            box-sizing: border-box;

            .img:first-child {
                width: 36Px;
                height: 30Px;
                margin-left: 12Px;
                margin-bottom: 15Px;
            }

            .img:last-child {
                width: 30Px;
                height: 30Px;
                margin: 15Px;
            }
        }



    }

    .bigTitle {
        width: 384Px;
        height: 90Px;
        font-size: 64Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 75Px;
    }


    .littleText {
        width: 1030Px;
        height: 135Px;
        font-size: 32Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 38Px;
        margin-bottom: 60Px;
    }

    .shenQingContainer {
        width: 1272Px;
        height: 3630Px;
        height: 3730Px;
        background: #FFFFFF;
        border-radius: 24Px 24Px 24Px 24Px;
        opacity: 1;
        border: 1Px solid #BD0048;
        box-sizing: border-box;
        padding-top: 100Px;
        padding-left: 120Px;

        // padding-bottom: 67Px;
        .feiYongText {
            width: 470Px;
            height: 34Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
        }


        .pieContainer {
            margin-top: 60Px;
            margin-left: 403Px;
            padding: 33Px;
            box-sizing: border-box;
            position: relative;

            .pie88 {
                width: 160Px;
                height: 160Px;
                border-radius: 80Px;
                background-color: #BD0048;
                position: relative;
                display: inline-block;
                overflow: hidden;

                .pie_left {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 80Px;
                    height: 160Px;
                    overflow: hidden;
                    background-color: #2A7EFB;
                }

                .pie_right {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 80Px;
                    height: 160Px;
                    overflow: hidden;

                }

                .pie_right::after {
                    content: "";
                    height: 160Px;
                    width: 80Px;
                    border-left: 80Px solid #2A7EFB;
                    position: absolute;
                    right: 0;
                    top: 0;
                    border-radius: 80Px;
                    transform: rotate(-137deg);
                }
            }


            .border {
                top: 31Px;
                position: absolute;
                width: 160Px;
                height: 160Px;
                border-style: dashed;
                border-width: 0.5Px;
                border-color: #A2A2A2;
                z-index: 6;
            }

            .pieText {
                text-align: center;
                position: absolute;
                font-size: 16Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;

                height: 36Px;
                line-height: 36Px;
                background: rgba(51, 51, 51, 0.7);
                border-radius: 4Px;
            }

            .pieText1 {
                width: 130Px;
                top: 28%;
                left: 138Px;
            }

            .pieText2 {
                width: 180Px;
                bottom: 35%;
                left: -80Px;
            }
        }

        .bigTitle {
            width: 192Px;
            height: 67Px;
            font-size: 48Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 56Px;
        }

        .littleText {
            width: 557Px;
            height: 102Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
            margin-bottom: 60Px;
        }

        .littleRenQun {
            width: 144Px;
            height: 50Px;
            font-size: 36Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 42Px;
        }

        .renQunTable1 {
            width: 1032Px;
            border-collapse:collapse;
            height: 360Px;
            background: #F7F7F7;
            border-radius: 8Px 8Px 8Px 8Px;
            border: 1Px solid #999999;
            margin-bottom: 100Px;

            td {
                width: 33.33%;
                height: 33.33%;
                text-align: center;
                font-size: 24Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 35Px;
                padding: 25Px 48Px;
                border-left: 1Px solid #999999;
                border-top: 1Px solid #999999;

            }
        }

        .shouliText {
            width: 552Px;
            height: 68Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
            margin-bottom: 60Px;
        }

        .flexContainer {
            // width: 100%;
            height: 148Px;
            margin-right: 120Px;
            margin-bottom: 100Px;
            display: flex;

            .flexItem {
                .img {
                    height: 60Px;
                    margin-bottom: 20Px;
                }

                .img:nth-child(1) {
                    width: 70.8Px;

                }

                .img:nth-child(2) {
                    width: 70.28Px;

                }

                .img:nth-child(3) {
                    width: 56.68Px;

                }

                .img:nth-child(4) {
                    width: 4.37Px;

                }

                .flexText {
                    width: 100%;
                    height: 68Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28Px;
                    text-align: center;
                }
            }

            .flexItem:nth-child(1) {
                width: 225Px;
                margin-right: 98Px;
            }

            .flexItem:nth-child(2) {
                width: 170Px;
                margin-right: 107Px;
            }

            .flexItem:nth-child(3) {
                width: 170Px;
                margin-right: 107Px;
            }

        }

        .dengjiText {
            width: 504Px;
            height: 68Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
            margin-bottom: 95Px;
        }

        .zhuZhuangTu {
            // padding: 0Px 204Px;
            box-sizing: border-box;
            height: 431Px;
            position: relative;
            margin-bottom: 130Px;
            width: 1032Px;

            .zhuZhuangItem {
                position: absolute;
                bottom: 0;
                width: 100Px;

                .zhuZhuangLittleText {
                    width: 74Px;
                    height: 25Px;
                    font-size: 18Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21Px;
                    margin-bottom: 10Px;
                    text-align: center;
                }

                .red {

                    width: 100Px;

                    background: #BD0048;
                }

                .redLine {
                    width: 100Px;
                    height: 2Px;
                    background: #BD0048;
                    margin: 20Px 0Px;
                }

                .bigText {
                    text-align: center;
                    width: 96Px;
                    height: 34Px;
                    font-size: 24Px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 28Px;
                }
            }

            .zhuZhuangItem1 {
                left: 204Px;

                .red {
                    height: 160Px;
                }

                .bigText {
                    width: 96Px;
                }
            }

            .zhuZhuangItem2 {
                left: 466Px;

                .red {
                    height: 240Px;
                }

                .bigText {
                    width: 48Px;
                }
            }

            .zhuZhuangItem3 {
                left: 728Px;

                .red {
                    height: 320Px;
                }

                .bigText {
                    width: 96Px;
                }
            }
        }

        .bigTitleFuWu {
            width: 336Px;
            height: 67Px;
            font-size: 48Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 56Px;
        }

        .changHuXianText {
            width: 326Px;
            height: 34Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
            margin-bottom: 30Px;
        }

        .renQunTable2 {
            width: 1005Px;
            height: 366Px;
            background: #F7F7F7;
            border-radius: 8Px 8Px 8Px 8Px;
            opacity: 1;
            border: 1Px solid #999999;
            margin-bottom: 30Px;
            border-collapse:collapse;
            td {
                width: 20%;
                height: 16.66%;
                text-align: center;
                font-size: 16Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 19Px;
                padding: 19Px 32Px;
                border-left: 1Px solid #999999;
                border-top: 1Px solid #999999;

            }
        }

        .renQunTable3 {
            border-collapse:collapse;
            width: 1005Px;
            height: 183Px;
            background: #F7F7F7;
            border-radius: 8Px 8Px 8Px 8Px;
            opacity: 1;
            border: 1Px solid #999999;
            margin-bottom: 100Px;

            td {
                width: 20%;
                height: 33.33%;
                text-align: center;
                font-size: 16Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 19Px;
                padding: 19Px 32Px;
                border-left: 1Px solid #999999;
                border-top: 1Px solid #999999;

            }
        }

    }
}

.allContainer {
    width: 1728Px;
    margin: 0 auto;
}

.outLine {
    width: 100Px;
    height: 4Px;
    background: #BD0048;
    border-radius: 8Px 8Px 8Px 8Px;
    margin-top: 10Px;
    margin-bottom: 60Px;
}

.topContainer {
    position: relative;
    width: 1728Px;
    height: 820Px;
    // background-image: url("@/assets/pc/Rectangle 389@2x.png");
    background-size: 100%;
    box-sizing: border-box;
    padding-left: 228Px;
    padding-top: 240Px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(51, 51, 51, 0.25)), url('@/assets/pc/Rectangle 389@2x.png');

    .bigTitle {

        width: 448Px;
        height: 90Px;
        font-size: 64Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 75Px;
        z-index: 1;
    }

    .littleText {

        width: 544Px;
        height: 45Px;
        font-size: 32Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 38Px;
        margin-bottom: 60Px;
        z-index: 1;
    }

    .detail {

        width: 240Px;
        height: 66Px;
        background: #BD0048;
        border-radius: 8Px 8Px 8Px 8Px;
        font-size: 32Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 66Px;
        text-align: center;
        z-index: 1;
    }

    .yinYing {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(51, 51, 51, 0.25);
        top: 0;
        left: 0;
        z-index: 0;
    }
}

.aboutUsContainer {
    width: 1728Px;
    height: 720Px;
    background: #FFFFFF;

    box-sizing: border-box;
    padding-left: 228Px;

    .left {
        padding: 147Px 0Px;

        .bigTitle {
            width: 256Px;
            height: 90Px;
            font-size: 64Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 75Px;
        }

        .littleTitle {
            width: 629Px;
            height: 136Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 28Px;
            margin-bottom: 60Px;
        }

        .button {
            width: 200Px;
            height: 66Px;
            background: #FFFFFF;
            border-radius: 8Px 8Px 8Px 8Px;

            border: 1Px solid #BD0048;
            font-size: 24Px;
            text-align: center;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 66Px;
        }
    }

    .right {
        img {
            width: 728Px;
            height: 720Px;
        }
    }

}</style>