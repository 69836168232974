<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
  mounted() {
    const screenWidth = document.documentElement.clientWidth
    const isPhone = screenWidth < 993// 小于993视为平板及手机
    let randomNum = Date.now();
    // 获取 User Agent
    var userAgent = navigator.userAgent.toLowerCase();

    // 判断是否在微信中打开
    // 如果有链接，想要访问其他页面，不重置到首页

    if (window.location.href.indexOf('mbHome') == -1 && window.location.href.indexOf('pcHome') == -1 && window.location.href.length > 24) {
      // console.log("不是首页")

      // 如果是手机，判断一下会不会改成了电脑。是电脑的话跳转到pc端。
      if (!this.isMobile()) {
        // console.log("不是手机。判断一下会不会去电脑")
        if (userAgent.indexOf('micromessenger') !== -1) {
          window.location.href = '/#/pcHome' + '?t=' + Date.now();
          console.log('当前页面在微信中打开');
        } else {
          
          window.location.href = window.location.href.replaceAll("mb","pc")
        }
      } else {
        // console.log("是手机，判断一下要去哪")
        // 是手机
        if (window.location.href.indexOf('mbHome') == -1) {
          // 要去的不是首页，放行
          // 如果是手机，pc端的路径换成手机端的
          window.location.href = window.location.href.replaceAll("pc","mb")
        } else {
          this.$router.replace('/mbHome?r=' + randomNum)
        }

      }

    } else {
      // console.log("是首页")
      // 如果从网站入口进入，重置到首页
      if (this.isMobile() || isPhone) {
        if (userAgent.indexOf('micromessenger') !== -1) {
          window.location.href = '/#/mbHome' + '?t=' + Date.now();
          console.log('当前页面在微信中打开');
        } else {
          this.$router.replace('/mbHome?r=' + randomNum)
        }
      } else {
        if (userAgent.indexOf('micromessenger') !== -1) {
          window.location.href = '/#/pcHome' + '?t=' + Date.now();
          console.log('当前页面在微信中打开');
        } else {
          this.$router.replace('/pcHome?r=' + randomNum)
        }


      }
    }

  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iphone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQOBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOs|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>

<style lang="less">
.startFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.betweenFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.columnBetweenFlex {
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

}
</style>
