<template>
    <div class="pagination">
        <div class="item" v-for="item, index in totalNum - 1" v-if="item>=startNum &&item<=endNum" :class="{ active: item == currentIndex }"
            @click="changeCurrenIndex(item)">{{ item }}</div>
        <div class="item" v-if="totalNum > 8 && currentIndex!==totalNum && currentIndex!==totalNum-1">…</div>
        <div class="item" :class="{ active: totalNum == currentIndex }" @click="changeCurrenIndex(totalNum)">{{ totalNum }}</div>
        <div class="item" @click="add">></div>

    </div>
</template>
    
<script>
export default {
    name: "Pagination",
    props: [
        'totalNum'
    ],
    data() {
        return {
            currentIndex: 1,
            startNum:0,
            endNum:0
        }
    },
    mounted() {
        this.setStartAndEnd()
    },
    methods: {
        changeCurrenIndex(index) {
            this.currentIndex = index
            this.setStartAndEnd()
        },
        add(){
            if(this.currentIndex == this.totalNum){
                return
            }
            this.currentIndex ++
            this.setStartAndEnd()
        },
        setStartAndEnd(){
            if(this.totalNum < 7){
                this.startNum = 0
                this.endNum = this.totalNum
            }
            if(this.totalNum>7){
                if(this.currentIndex < 7){
                    this.startNum = 0
                    this.endNum = 0 + 8
                }
                if(this.currentIndex >3){
                    this.startNum = this.currentIndex - 3
                    this.endNum = this.currentIndex + 3
                }
            }
        }
    },
    computed: {

    }
}
</script>
    
<style lang="less" scoped>
.active {
    background: #BD0048 !important;
    color: #FFFFFF !important;
}

.pagination {

    display: flex;
    justify-content: center;
    align-items: center;

    .item {
        width: 36Px;
        height: 36Px;
        background: #FFFFFF;
        border-radius: 4Px 4Px 4Px 4Px;
        font-size: 20Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 36Px;
        text-align: center;
        margin-right: 10Px;
    }
}
</style>