<template >
    <div class="xinWenCenterContainer">
        <pcHeader current="1"></pcHeader>
        <div class="topImageContainer">
            <div class="title">新闻中心</div>
            <div class="line"></div>
            <div class="littleText">了解最新的医疗行业新闻和公司动态</div>
        </div>
        <router-view></router-view>
        <pcFooter></pcFooter>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less">
.xinWenCenterContainer{
    width: 1728Px;
    margin: 0 auto;
}   
.topImageContainer {

    width: 1728Px;
    height: 400Px;
    background-size: 100%;
    box-sizing: border-box;
    padding-top: 160Px;
    padding-left: 228Px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25), rgba(51, 51, 51, 0.25)), url('@/assets/pc/Rectangle 452@2x.png');
    margin: 0 auto;
    .title {
        // width: 192Px;
        height: 67Px;
        font-size: 48Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 56Px;
        margin-bottom: 10Px;
    }

    .line {
        width: 100Px;
        height: 4Px;
        background: #BD0048;
        border-radius: 8Px 8Px 8Px 8Px;
        margin-bottom: 20Px;
    }

    .littleText {
        height: 34Px;
        font-size: 24Px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 28Px;
    }

}
</style>