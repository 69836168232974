<template>
    <div class="detailContainer">
        <div class="topContainer ">
            <div class="text">新闻中心</div>
            <img src="@/assets/pc/rightJianTou.png" class="jianTou">
            <div class="text">企业资讯</div>
            <img src="@/assets/pc/rightJianTou.png" class="jianTou">
            <div class="text">远离心梗 专家提醒：秋冬交替控制好这些风险因素</div>
        </div>
        <div class="contentContainer">
            <div class="topTitle">
                <div class="big">远离心梗 专家提醒：秋冬交替控制好这些风险因素</div>
                <div class="day">2023/11/16</div>
            </div>
            <div class="bottomContent">
                <div class="long">
                    心梗是血管动脉粥样硬化形成斑块堵塞血管导致。”首都医科大学附属北京安贞医院急诊危重症中心
                    副主任医师段小春介绍，冠心病家族史、高血压、高血脂、高血糖、超重等都是损害血管、增加斑块
                    不稳定性的危险因素。年轻心梗患者增加，可能和生活不规律、经常熬夜、身体处于极度兴奋或疲惫
                    的状态、饮食不健康、抽烟等因素相关。另外，情绪、悲伤、抑郁、过度高兴也会导致心脏损伤，甚
                    至引发心梗。此外，心梗的发作还与气温变化有一定关系。
                    心梗的临床表现多种多样。段小春介绍，最典型的就是因心脏血管梗塞，心肌细胞缺血坏死，心前区
                    或左侧胸部出现一种剧烈的疼痛或闷痛，部分人疼痛感可放射至后背、左侧肩部、脖子、下颌、左上
                    肢。部分人疼痛表现不典型，心前区疼痛不明显，而是有明显的牙疼、上腹部疼痛或脖子疼。一些患
                    者情况危重，可能出现急性心衰，表现为喘憋、满头大汗、血压下降；有些人还会心率变慢、头晕、
                    眼花、昏厥；也有人会表现为心率特别快，甚至出现心律失常。
                    如果救治不及时，会出现心衰、并发症，后续也会因心脏功能的改变影响身体健康。“心梗是一个突发
                    疾病，先兆情况不好把握。”段小春提示，患有高血压、高血脂、高血糖，或近期有熬夜、休息不好、
                    精神压力大等情况，如果出现心脏不舒服，如心慌、憋气，特别是活动以后感觉这种症状更加明显，
                    需要重视起来，最好前往医院检查，如有问题可及早处理。
                    心梗是血管动脉粥样硬化形成斑块堵塞血管导致。”首都医科大学附属北京安贞医院急诊危重症中心
                    副主任医师段小春介绍，冠心病家族史、高血压、高血脂、高血糖、超重等都是损害血管、增加斑块
                    不稳定性的危险因素。年轻心梗患者增加，可能和生活不规律、经常熬夜、身体处于极度兴奋或疲惫
                    的状态、饮食不健康、抽烟等因素相关。另外，情绪、悲伤、抑郁、过度高兴也会导致心脏损伤，甚
                    至引发心梗。此外，心梗的发作还与气温变化有一定关系。
                    心梗的临床表现多种多样。段小春介绍，最典型的就是因心脏血管梗塞，心肌细胞缺血坏死，心前区
                    或左侧胸部出现一种剧烈的疼痛或闷痛，部分人疼痛感可放射至后背、左侧肩部、脖子、下颌、左上
                    肢。部分人疼痛表现不典型，心前区疼痛不明显，而是有明显的牙疼、上腹部疼痛或脖子疼。一些患
                    者情况危重，可能出现急性心衰，表现为喘憋、满头大汗、血压下降；有些人还会心率变慢、头晕、
                    眼花、昏厥；也有人会表现为心率特别快，甚至出现心律失常。
                    如果救治不及时，会出现心衰、并发症，后续也会因心脏功能的改变影响身体健康。“心梗是一个突发
                    疾病，先兆情况不好把握。”段小春提示，患有高血压、高血脂、高血糖，或近期有熬夜、休息不好、
                    精神压力大等情况，如果出现心脏不舒服，如心慌、憋气，特别是活动以后感觉这种症状更加明显，
                    需要重视起来，最好前往医院检查，如有问题可及早处理。
                </div>
                <div class="titleItem titleItem1">上一篇：远离心梗 专家提醒：秋冬交替控制好这些风险因素</div>
                <div class="titleItem">下一篇：远离心梗 专家提醒：秋冬交替控制好这些风险因素</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.detailContainer {
    width: 1728Px;
    background: #F7F7F7;
    box-sizing: border-box;
    padding: 0Px 228Px;
    padding-bottom: 60Px;
    margin: 0 auto;
    .topContainer {
        padding: 33Px 0Px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
            height: 34Px;
            font-size: 24Px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 28Px;
        }

        .jianTou {
            width: 18Px;
            height: 18Px;
            margin: 0 10Px;
        }
    }

    .contentContainer {
        width: 1272Px;
        box-sizing: border-box;
        padding: 60Px 0Px 55Px 0Px;
        background: #FFFFFF;
        border-radius: 8Px 8Px 8Px 8Px;
        margin: 0 auto;
        .topTitle {
            border-bottom: 1Px solid #D9D9D9;
            box-sizing: border-box;
            padding-left: 60Px;
            padding-bottom: 10Px;

            .big {
                font-size: 36Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 45Px;
                margin-bottom: 10Px;
            }

            .day {
                font-size: 16Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 33Px;

            }
        }

        .bottomContent {
            padding-left: 60Px;
            padding-right: 132Px;
            box-sizing: border-box;
            padding-top: 42Px;

            .long {
                margin-bottom: 60Px;
                font-size: 20Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 40Px;
            }

            .titleItem {
                height: 34Px;
                font-size: 24Px;
                font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 32Px;
            }

            .titleItem1 {
                margin-bottom: 30Px;
            }
        }
    }
}
</style>