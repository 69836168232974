import Vue from 'vue'
import VueRouter from 'vue-router'
import pcHome from '@/pages/pc/pcHome.vue'
import mbHome from '@/pages/mb/mbHome.vue'
import mbFuWuCenter from '@/pages/mb/mbFuWuCenter.vue'
import mbContact from '@/pages/mb/mbContact.vue'
import mbXinWenCenter from '@/pages/mb/mbXinWenCenter.vue'
import mbXinWenList from '@/pages/mb/mbXinWenList.vue'
import mbCenterDetail from '@/pages/mb/mbCenterDetail.vue'
import pcXinWenCenter from '@/pages/pc/pcXinWenCenter.vue'
import pcCenterList from '@/pages/pc/pcCenterList.vue'
import pcCenterDetail from '@/pages/pc/pcCenterDetail.vue'
Vue.use(VueRouter)
let OringinPush = VueRouter.prototype.push;
let OringinReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        OringinReplace.call(this, location, resolve, reject)
    } else {
        OringinReplace.call(this, location, () => { }, () => { })
    }
}
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        OringinPush.call(this, location, resolve, reject)
    } else {
        OringinPush.call(this, location, () => { }, () => { })
    }
}
const routes = [
    { path: '/pcHome', component: pcHome },
    {
        path: '/',
        redirect: '/pcHome'
    },
    {
        path: '/pcHome',
        component: pcHome
    },
    {
        path: '/mbHome',
        component: mbHome
    },
    {
        path: '/mbFuWuCenter',
        component: mbFuWuCenter
    },
    {
        path: '/mbContact',
        component: mbContact
    },
    {
        path: '/mbXinWenCenter',
        component: mbXinWenCenter,
        redirect: "/mbXinWenCenter/mbCenterList",
        children: [
            {
                path: "/mbXinWenCenter/mbCenterList",
                name: "mbCenterList",
                component: mbXinWenList,
            },
            {
                path: "/mbXinWenCenter/mbCenterDetail",
                name: "mbCenterDetail",
                component: mbCenterDetail,
            },
        ],


    },
    {
        path: '/pcXinWenCenter',
        component: pcXinWenCenter,
        redirect: "/pcXinWenCenter/pcCenterList",
        children: [
            {
                path: "/pcXinWenCenter/pcCenterList",
                name: "pcCenterList",
                component: pcCenterList,
            },
            {
                path: "/pcXinWenCenter/pcCenterDetail",
                name: "pcCenterDetail",
                component: pcCenterDetail,
            },
        ],


    },

]
const router = new VueRouter({
    routes
})
export default router